import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";

Vue.use(Vuex);
const initialState = () => {
  return {
    user: {},
    uid: null,
    error: null,
    chapters: null,
    presentations: null,
    presentationData: {
      id: null,
      activeSlide: null,
      presentationStarted: null,
      browsingActive: null,
      activeChapter: null,
      openSubslide: null,
      endScreenActive: null,
      clickMarker: {
        X: null,
        Y: null,
      },
    },
  };
};
const emptyCollection = {
  activeSlide: 0,
  activeChapter: 0,
  browsingActive: false,
  clickMarker: {X: null, Y: null},
  openSubslide: null,
  presentationStarted: false,
  endScreenActive: false,
  chapters: [{
    chapterTitle: "",
    slides: [{
      description: "",
      title: "",
      subslides: [],
      imageUrl: ""
    }],
  }],
  title: "Titel",
  voting: false,
  user: null,
}

export default new Vuex.Store({
  state: initialState(),
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setUid(state, payload) {
      state.uid = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setChapters(state, payload) {
      state.chapters = payload;
    },
    setPresentations(state, payload) {
      state.presentations = payload;
    },
    setPresentationData(state, payload) {
      state.presentationData = payload;
    },
    setPresentationState(state, payload) {
      state.presentationData = payload;
    },
  },
  actions: {
    authAction({ commit }) {
      fb.auth.onAuthStateChanged((user) => {
        if (user) {
          commit("setUid", user.uid);
        } else {
          commit("setUser", null);
        }
      });
    },
    loginAction({ commit }, form) {
      fb.auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then((response) => {
          // Signed in
          var userData = {};
          fb.db
            .collection("users")
            .doc(response.user.uid)
            .onSnapshot((snapshot) => {
              userData = { ...snapshot.data() };
              commit("setUser", userData);
            });
          router.push("/presenter");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          commit("setError", {
            errorCode,
            errorMessage,
          });
        });
    },
    logoutAction({ commit }) {
      fb.auth
        .signOut()
        .then(() => {
          router.push("/");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          commit("setError", {
            errorCode,
            errorMessage,
          });
        });
    },
    getPresentationsAction({ commit }) {
      fb.db
        .collection("presentations")
        .get()
        .then((snap) => {
          const _presentations = [];
          snap.forEach((doc) => {
            _presentations.push({
              id: doc.id,
              title: doc.data().title,
            });
          });
          commit("setPresentations", _presentations);
        });
    },
    DELETEPRESENTATIONACTION({ commit }, id) {
      fb.db.collection("presentations").doc(id).delete().then(() => {
        commit("setPresentationData", null)
      }).catch((error) => {
        commit("setError", error)
      });
    },
    CREATEPRESENTATIONACTION() {
      fb.db.collection('presentations').add({ ...emptyCollection, user: this.state.uid })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        router.push({name: 'EditPresentation', params: {id: docRef.id}})
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    GETPRESENTATIONACTION({ commit }, id) {
      var _chaptersData = [];
      fb.db
        .collection("presentations")
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            doc.data().chapters.forEach(function(chapter) {
              _chaptersData.push({
                ...chapter,
              });
            });
            commit("setChapters", _chaptersData);
          } else {
            console.log("No data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      fb.db
        .collection("presentations")
        .doc(id)
        .onSnapshot((snapshot) => {
          let presentationState = {
            id: snapshot.id,
            activeSlide: snapshot.data().activeSlide,
            presentationStarted: snapshot.data().presentationStarted,
            browsingActive: snapshot.data().browsingActive,
            endScreenActive: snapshot.data().endScreenActive,
            openSubslide: snapshot.data().openSubslide,
            activeChapter: snapshot.data().activeChapter,
            voting: snapshot.data().voting
          };
          commit("setPresentationState", presentationState);
        });
    },
    firstSlideAction() {
      let id = this.state.presentationData.id;
      fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeSlide: 0,
            activeChapter: 0
          });
    },
    nextSlideAction() {
      if (this.state.presentationData.activeSlide < this.state.chapters[this.state.presentationData.activeChapter].slides.length - 1) {
        let id = this.state.presentationData.id;
        let activeSlide = this.state.presentationData.activeSlide;
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeSlide: activeSlide + 1,
          });
      } else if ((this.state.presentationData.activeSlide == this.state.chapters[this.state.presentationData.activeChapter].slides.length - 1) && this.state.presentationData.activeChapter < this.state.chapters.length - 1) {
        let id = this.state.presentationData.id;
        let activeChapter = this.state.presentationData.activeChapter;
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeSlide: 0,
            activeChapter: activeChapter + 1
          });
      }
    },
    previousSlideAction() {
      if (this.state.presentationData.activeSlide > 0) {
        let id = this.state.presentationData.id;
        let activeSlide = this.state.presentationData.activeSlide;
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeSlide: activeSlide - 1,
          });
      } else if ((this.state.presentationData.activeSlide == 0) && this.state.presentationData.activeChapter > 0) {
        let id = this.state.presentationData.id;
        let activeChapter = this.state.presentationData.activeChapter - 1;
        let prevslide = this.state.chapters[activeChapter].slides.length - 1;
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeSlide: prevslide,
            activeChapter: activeChapter
          });
      }
    },
    togglePresentationStarted() {
      let id = this.state.presentationData.id;
      if (this.state.presentationData.presentationStarted) {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            presentationStarted: false,
          });
      } else {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            presentationStarted: true,
          });
      }
    },
    toggleBrowsingActive() {
      let id = this.state.presentationData.id;
      if (this.state.presentationData.browsingActive) {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            browsingActive: false,
          });
      } else {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            browsingActive: true,
            endScreenActive: false,
          });
      }
    },
    toggleEndScreen() {
      let id = this.state.presentationData.id;
      if (this.state.presentationData.endScreenActive) {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            endScreenActive: false,
          });
      } else {
        fb.db
          .collection("presentations")
          .doc(id)
          .update({
            endScreenActive: true,
            browsingActive: false,
          });
      }
    },
  },
  getters: {
    isUserAuth(state) {
      return !!state.user;
    },
    getUserData(state) {
      return state.user;
    },
    getUid(state) {
      return state.uid;
    },
    getChapters(state) {
      return state.chapters;
    },
    getPresentations(state) {
      return state.presentations;
    },
    getPresentationData(state) {
      return state.presentationData;
    },
    getActiveSlide(state) {
      return state.presentationData.activeSlide;
    },
    getActiveChapter(state) {
      return state.presentationData.activeChapter;
    }
  },
});
