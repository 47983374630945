<template>
  <div class="home">
    <h1>Logga in</h1>
    <form @submit.prevent="login">
      <input type="email" placeholder="Mailadress" v-model="email" />
      <input type="password" placeholder="Lösenord" v-model="password" />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store.dispatch("loginAction", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss">
form {
  display: flex;
  flex-direction: column;
  button {
    margin: 1rem;
    box-sizing: border-box;
    border: 2px solid black;
    padding: 0.5rem 2rem;
    align-self: flex-end;
    background: white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }
  input {
    box-sizing: border-box;
    border: 2px solid black;
    padding: 0.5rem;
    margin: 1rem;
  }
}
.home {
  max-width: 40rem;
  margin: 1rem auto;
}
</style>
