<template>
  <div id="app">
    <div id="nav">
      <router-link to="/presenter">Inställningar</router-link> |
      <router-link to="/slideadmin">Presentationer</router-link> |
      <a href="#" v-if="isUserAuth" @click="logoutAction">Logga ut</a>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'NovartisAdmin',
  metaInfo: {
    title: 'Novartis Admin',
    htmlAttrs: {
      lang: 'sv'
    }
  },
  methods: {
    ...mapActions(["authAction", "logoutAction"]),
  },
  computed: {
    ...mapGetters(["isUserAuth"]),
  },
  mounted() {
    this.authAction();
  },
};
</script>

<style lang="scss">
:root {
  --navheight: 3rem;
  --slidenavheight: 6rem;
}
div {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  height: var(--navheight);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 0 0.5rem;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.btn {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 700;
  display: inline-block;
  padding: 5px 10px;
  background-color: #555;
  color: white;
  margin-left: 0.5rem;
  transition: background-color 100ms ease-in-out;
  cursor: pointer;
  position: relative;
  border-radius: 0.2rem;
  border: 1px solid #333;
  &:hover {
    background-color: #333;
  }
  .icon {
    margin-right: 0.5rem;
  }
  &.btn__large {
    font-size: 0.8rem;
    padding: 10px 15px;
    flex: 0 0 auto;
    align-self: flex-end;
  }
  &.btn__warning {
    background-color: #a00000;
    border: 1px solid #8b0000;
    &:hover {
      background-color: #8b0000;
    }
  }
  &.active {
    background-color: #2470bd;
  }
}
.w100 {
  width: 100%;
}
</style>
