import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDHouTznyUVqYKEJXeeNNwbl23WvzNHbYI",
  authDomain: "novartis-presentation.firebaseapp.com",
  databaseURL: "https://novartis-presentation-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "novartis-presentation",
  storageBucket: "novartis-presentation.appspot.com",
  messagingSenderId: "584396286505",
  appId: "1:584396286505:web:37634f80e7f9333eab0a38"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
// collection references
// const usersCollection = db.collection('users')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  storage,
  // usersCollection,
  // postsCollection,
  // commentsCollection,
  // likesCollection
}