import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "../firebase";
import VueMeta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/presenter",
    name: "PresenterSettings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "presenterSettings" */ "../views/PresenterSettings.vue"
      ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/slideadmin",
    name: "SlideAdmin",
    component: () =>
      import(/* webpackChunkName: "slideAdmin" */ "../views/SlideAdmin.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/presentation/:id",
    name: "Presentation",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "presentation" */ "../views/Presentation.vue"
      ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/create/",
    name: "CreatePresentation",
    component: () =>
      import(
        /* webpackChunkName: "createPresentation" */ "../views/CreatePresentation.vue"
      ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/edit/:id",
    name: "EditPresentation",
    component: () =>
      import(
        /* webpackChunkName: "editPresentation" */ "../views/EditPresentation.vue"
      ),
    meta: {
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      alert("Du måste vara inloggad för att se den här sidan");
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

export default router;
